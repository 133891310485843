/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function Model({ ...props }) {
  const group = useRef();
  const { nodes, materials } = useGLTF("/lesnipe_logo_draco.gltf");
  return (
    <group scale={0.3} ref={group} {...props} dispose={null}>
      <mesh
        geometry={nodes.Text.geometry}
        material={materials["Material #75"]}
        rotation={[Math.PI / 2, 0, 0]}
      />
      <group
        position={[-6.89, -0.4, -0.33]}
        rotation={[Math.PI / 2, -Math.PI / 2, 0]}
        scale={[0.25, 0.4, 0.3]}
      >
        <mesh
          geometry={nodes.Mesh.geometry}
          material={materials["Metallic Paint Glossy - Black"]}
        />
        <mesh
          geometry={nodes.Mesh_1.geometry}
          material={materials["Plastic Fine Textured - Black"]}
        />
        <mesh
          geometry={nodes.Mesh_2.geometry}
          material={materials["Plastic Smooth - Black"]}
        />
        <mesh
          geometry={nodes.m40a3_bolt.geometry}
          material={materials["Metallic Paint Glossy - Black"]}
          position={[7.17, 0.81, -0.96]}
          rotation={[0, 0, -Math.PI / 2]}
          scale={0.94}
        />
        <mesh
          geometry={nodes.m40a3_cheekwell.geometry}
          material={materials["Metallic Paint Glossy - Black"]}
          position={[16.99, 0.79, -0.02]}
          rotation={[0, 0, Math.PI / 2]}
        />
        <mesh
          geometry={nodes.m40a3_x16scope.geometry}
          material={materials["Metallic Paint Glossy - Black"]}
          position={[-0.8, 0.79, -3.14]}
          rotation={[0, 0, Math.PI / 2]}
          scale={1.48}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/lesnipe_logo_draco.gltf");
